import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction, toJS } from "mobx";
import {getMethodData,putMethod, patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";
import axios from 'axios';

export class ProductStore {
    Token = null;
    entries = [{ name: "", image: null }];
    checkbox = 0;
    BulkData=[];
    EditCheckBox= '';
    products = [];
    loadproduct = false;
    Pageloadproduct=false;
    errloadproduct = "";
    nolist = false;
    indexPage=1;
    Count=1;
    perPage="";
    filterVisibility=true;
    singleproduct;
    filtertrue= false;
    addloadproduct= false;
    addedloadproduct=false;
    bulkloadproduct=false;
    erraddproduct="";
    name= "";
    price= "";
    description= "";
    category= "";
    keywords="";
    status="";
    image="";
    BulkImage=[];
    BulkName=[];
    audio="";
    audiosend="";
    imageurl="";
    imageLoopUrl=[];
    ProductType= ""
    activePage=1;
    handelLoopName="";
    filetype=""
    nofilterwithpagination= false;
    productid="";

    saved = false

    loadCategory=false;
    errCategorylist = "";
    noCategorylist=true;
    Categorylist=[]

    loadProducttype=false;
    errProducttypelist = "";
    noProducttypelist=true;
    Producttypelist=[]

    args= {}
    previousValues = {
      name: "",
      status:"",
      category_id: "",
      product_type: "",
    };
    constructor(isloading = false) {
        makeAutoObservable(this);
        this.args = isloading;
        
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getProducts(isloading?.pagginationcount);
            this.getProducttypeList();
            return
        }
        if(isloading.editloading){
            this.getproductone(isloading.id);
            this.getProducttypeList()
        
            return
        }
        if(isloading.categoryproduct){
          this.getFilterProducts(isloading.page,isloading.categoryid)
        }
        
    }

    // setfiletype= (text) => {
    //   this.filetype = text
    // }
 
  
  setbulkData=(e)=>{
    this.BulkData= e
  }




    setname = (text) =>  {
        this.name = text;
    }
    setprice = (text) =>  {
        this.price = text;
    }
    setdescription = (text) =>  {
        this.description = text;
    }
    setcategory = (text) =>  {
    console.log(text)

        this.category = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setcheckbox=(text)=>{
      this.checkbox =text
  
    }
    setEditcheckbox=(text)=>{
      this.EditCheckBox =text
    }
    setclear= ()=> {
      this.image = "";
      this.audio = "";
      this.imageurl = ""
    }
    setIndexPage=(e)=>{
      this.indexPage=e;
    }
    setCount=(e)=>{
      this.Count=e;
    }
    setPerPage=(e)=>{
      this.perPage=e;
    }
    setFilterVisibility=(e)=>{
      this.filterVisibility=e;
    }
    setimage = (text,type) =>  {
      this.filetype = type
      if(text){
        // console.log(type )
        if (type != "mp3") {
          this.image = text;
          this.imageurl = URL.createObjectURL(text[0]);
        }
        else{
          this.audiosend = text;
          const file = text[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            const audioData = e.target.result;
            runInAction(()=> {
              this.audio = audioData;
            })
          };
          reader.readAsDataURL(file);
        }
      }
    }
    setImageLoop= (text) => {
     
      if (text) {
   
          this.imageLoopUrl.push(URL.createObjectURL(text[0]));
        
      }
    };
  
    setToken = (text) =>  {
      this.Token = text;
    }
    getstatusid = (text) => {
        this.productid = text;
    }
    setProductType = (text) =>{
      this.ProductType = text;
    }
    setActivePage = (text) =>{
      this.activePage = text;
    }
    SethandelLoopName  = (text) =>{
      this.handelLoopName = text;
    }
    NofilterwithPagination = (e) => {
      runInAction(() => {
          this.nofilterwithpagination = e
      })
    }

        // get product List
        getProducts = (pageNumber, currentPage,Token) => {
            runInAction(() => {
                this.loadproduct = true;
                this.errloadproduct = "";
                this.saved = false;
                });
            getMethodData(`/admin/products?page=${pageNumber ?? currentPage ?? 1}`,this.Token||Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                    this.loadproduct = false;
                    this.errloadproduct = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                    this.loadproduct = false;
                    this.errloadproduct = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.products = resp?.data?.data?.data;
                    this.Count = resp?.data?.data?.total;
                    this.perPage = resp?.data?.data?.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.filterVisibility = true;
                    this.saved = false;
                    // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadproduct = false;
                    this.errloadproduct = resp?.data?.message;
                });
                })
                .catch((error) => {
                runInAction(() => {
                    this.loadproduct = false;
                    if(!error.response){
                        this.errloadproduct = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadproduct = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
//         loadTableData = async (pageNumber) => {
//           runInAction(() => {
//             this.Pageloadproduct = true;
//             this.errloadproduct = "";
//             this.saved = false;
//             this.setIndexPage(1);

//             });

      
//           try {
//             const response = await getMethodData(`/admin/products?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
//             if (response?.data?.status === false) {
//               runInAction(() => {
//               this.Pageloadproduct = false;
//               this.errloadproduct = response?.data?.message
//               });
//               return;
//           }
//           if (response?.data?.status === true) {
//             runInAction(() => {
//               this.Pageloadproduct = false;
//               this.errloadproduct = response?.data?.message;
//               // console.log(resp?.data?.data.length);
//               if(response?.data?.data?.data?.length === 0) {
//                   this.nolist = true;
//               }else{
//                   this.nolist = false;
//               }
//                 this.setCount(response.data.data.total);
//                 this.setPerPage(response.data.data.per_page);
//                 this.setActivePage(response.data.data.current_page)
//                 this.products = response?.data?.data?.data;
//                 this.filterVisibility = true;
// });
//           }

        
      
//           } catch (error) {
//             runInAction(() => {
//               this.Pageloadproduct = false;
//               console.error("Error loading table data:", error);
//           });
//           }
//         };
        

       loadTableData = async (pageNumber) => {
          runInAction(() => {
            this.Pageloadproduct = true;
            this.errloadproduct = "";
            this.saved = false;
            this.setIndexPage(1);
    
            });
    
      
          try {
            const response = await getMethodData(`/admin/products?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
            if (response?.data?.status === false) {
                            runInAction(() => {
                            this.Pageloadproduct = false;
                            this.errloadproduct = response?.data?.message
                            });
                            return;
                        }
                        if (response?.data?.status === true) {
                          runInAction(() => {
                            this.Pageloadproduct = false;
                            this.errloadproduct = response?.data?.message;
                            // console.log(resp?.data?.data.length);
                            if(response?.data?.data?.data?.length === 0) {
                                this.nolist = true;
                            }else{
                                this.nolist = false;
                            }
              this.setCount(response.data.data.total);
              this.setPerPage(response.data.data.per_page);
              this.setActivePage(response.data.data.current_page)
              this.products = response?.data?.data?.data;
              this.filterVisibility = true;
    });
          }
    
        
      
          } catch (error) {
            runInAction(() => {
              this.loadOrder = false;
              console.error("Error loading table data:", error);
          });
          }
        };
        
        sendPage = (pageNumber) => {
          axios
          .getMethodData(`/admin/products?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
            .then((res) => {
          
              this.products = res?.data?.data?.data;
              this.count = res.data.data.total;
              this.perPage = res.data.data.per_page;
            })
            .catch((error) => {
              if(!error.response){
                this.errloadproduct = error?.message;
                toast.error(error?.message);
            }
            this.errloadproduct = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
            });
        };

        //  LoadTableData=async(pageNumber) => {
        //     this.setIndexPage(1);
      
        //     await getMethodData(`/admin/products?page=${1}`,this.Token)
              
        //       .then((res) => {
        //         console.log(res?.data?.data?.data);
        //         this.setCount(res.data.result.data.total);
        //         this.setPerPage(res.data.result.data.per_page);
        //         this.products = res?.data?.data?.data;
       
        //         this.setFilterVisibility(true);
        //       });
      
        //     // reset();
        //   // [ this.Token]
        //   // [this.products, this.Count]
        // };
      
        // const sendPage = (pageNumber) => {
        //   axios
        //   .get(
        //     getMethodData(`/admin/products?page=${pageNumber === 1 ? 1 : pageNumber}`,Token)
        //   )
        //     .then((res) => {
        //       setUser(res.data.result.data.data);
        //       setCount(res.data.result.data.total);
        //       setPerPage(res.data.result.data.per_page);
      
        //     });
        // };

            //get product edit data (show)

    getproductone =(id) => {
        getMethodData( `/admin/products/edit/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loadproduct = true;
                this.errloadproduct = "";
              });
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadproduct = false;
              this.errloadproduct = resp.data.message;
              // toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadproduct = false;
                this.singleproduct =  resp?.data?.data
                this.name = resp?.data?.data?.name
                this.price = resp?.data?.data?.price
                this.description = resp?.data?.data?.description
                this.category = resp?.data?.data?.category_id
                this.checkbox= resp?.data?.data?.is_popular
                this.ProductType = resp?.data?.data?.product_type_id
                this.status = resp?.data?.data?.status
                this.image = resp?.data?.data?.image
                this.audio = resp?.data?.data?.image
                this.filetype = resp?.data?.data?.image?.split('.').pop().toLowerCase()
                this.imageurl = resp?.data?.data?.image;
                this.getCategoryList(resp?.data?.data?.product_type_id)
            })
            return;
          }
          runInAction(() => {
            this.loadproduct = false;
            this.errloadproduct = resp.data.message;
            toast.error(resp.data.message);
          });
        })
        .catch((error) => {
          runInAction(() => {
              if(!error.response){
                this.loadproduct = false;
                this.errloadproduct = error?.message;
                toast.error(error?.message);
              }
                toast.error(error?.response?.data?.message);
                this.loadproduct = false;
                this.errloadproduct = error?.response?.data?.message;
          })
          return;
      })
    }

         // product add

 addproduct = () => {
  // console.log(this.audiosend)
  // console.log(this.image[0])
  // return
    runInAction(() => {
      this.addedloadproduct = true;
      this.erraddproduct = "";
    });
    const formData = new FormData();
    formData.append("name", this.name);
    formData.append("description", this.description);
    formData.append("price", this.price);
    formData.append("is_popular", this.checkbox);
    formData.append("category_id", this.category);
    formData.append("product_type", this.ProductType);
    formData.append("status", this.status);
    formData.append("image", this.filetype != "mp3" ? this.image[0] : this.audiosend[0]);
    
    postFormData(`/admin/products/store`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addedloadproduct = false;
            this.erraddproduct = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addedloadproduct = false;
              this.erraddproduct = resp?.data?.message;
              toast.success(resp.data.message);
               this.getProducts();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addedloadproduct = false;
          this.erraddproduct = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
                
              this.addedloadproduct = false;
              this.erraddproduct = error?.message;
              toast.error(error?.message);
            }
                
              toast.error(error?.response?.data?.message);
              this.addedloadproduct = false;
              this.erraddproduct = error?.response?.data?.message;
        })
        return;
    })
  };

  bulkUpload = () => {
    // console.log(this.audiosend)
    // console.log(this.image[0])
    // return
    const formData = new FormData();

      runInAction(() => {
        this.bulkloadproduct = true;
        this.erraddproduct = "";
      });
      for (let i = 0; i < this.BulkData.length; i++) {
        const item = this.BulkData[i];
        formData.append(`products[${i}][image]`, item.image);
        formData.append(`products[${i}][status]`, item.status);
        formData.append(`products[${i}][description]`, item.description == undefined ?  "" : item.description );
      }
      formData.append("category_id", this.category);
      formData.append("product_type", this.ProductType);
   

      // Use a for loop to iterate through the jsonData array and append each object as JSON to the FormData
   
 
      postFormData(`/admin/products/bulk-upload`,formData,this.Token)
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.bulkloadproduct = false;
              this.erraddproduct = resp?.data?.message;
              toast.error(resp.data?.data?.errors?.name[0]);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
                this.bulkloadproduct = false;
                this.erraddproduct = resp?.data?.message;
                toast.success(resp.data.message);
                this.saved = true;
              })
              return
          }
          runInAction(() => {
            this.bulkloadproduct = false;
            this.erraddproduct = resp?.data?.message;
            toast.error(resp.data.message);
          });
        })
        .catch((error) => {
          
          runInAction(() => {
              if(!error.response){
                  
                this.bulkloadproduct = false;
                this.erraddproduct = error?.message;
                toast.error(error?.message);
              }
                  
                toast.error(error?.response?.data?.message);
                this.bulkloadproduct = false;
                this.erraddproduct = error?.response?.data?.message;
          })
          return;
      })
    };

  editproduct = (id) => {
    if(!this.audio && !this.image){
       toast.warn("Please Upload File.")
      return
    }
    // if(!this.audio || !this.image){
    //   // return
    //   toast.warn("Please Upload File.")
    //   return
    // }
    runInAction(() => {
      this.addloadproduct = true;
      this.erraddproduct = "";
    });
    const formData = new FormData();
    formData.append("name", this.name);
    formData.append("description", this.description);
    formData.append("is_popular", this.checkbox);
    formData.append("price", this.price);
    formData.append("category_id", this.category);
    formData.append("product_type", this.ProductType);
    formData.append("status", this.status);
    // formData.append("image", this.filetype != "mp3" ? this.image[0] : this.audiosend[0]);
    // console.log(this.image,this.audiosend)
      if ((this.image || this.audiosend) && (this.image.length || this.audiosend.length) > 0) {
        formData.append("image", this.filetype != "mp3" ? this.image[0] : this.audiosend[0]);
        } else {
          formData.delete("image");
      }
    postFormData(`/admin/products/update/${id}`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadproduct = false;
            this.erraddproduct = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadproduct = false;
              this.erraddproduct = resp?.data?.message;
              toast.success(resp.data.message);
               this.getProducts();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadproduct = false;
          this.erraddproduct = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
              this.addloadproduct = false;
              this.erraddproduct = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloadproduct = false;
              this.erraddproduct = error?.response?.data?.message;
        })
        return;
    })
  };


    //Active and inactive
statusChange = (currentPage) => {
    runInAction(() => {
      this.loadproduct = true;
      this.errloadproduct = "";
    });
    getMethodData(`/admin/products/change-status/${this.productid}`,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadproduct = false;
            this.errloadproduct = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadproduct = false;
            this.errloadproduct = resp?.data?.message;
            if(this.args?.categoryid){
              this.getFilterProducts(this.args?.page,this.args?.categoryid)
            }
            else{
              this.getProducts(currentPage);
            }
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.loadproduct = false;
          this.errloadproduct = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadproduct = false;
              this.errloadproduct = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadproduct = false;
              this.errloadproduct = error?.response?.data?.message;
        })
        return;
    })
  };
  setfiltertrue=(e)=>{
    this.filtertrue = e;
  }

     //filter product api 

     getFilterProducts = (page,id,Token) => {
        runInAction(() => {
            this.loadproduct = true;
            this.errloadproduct = "";
            this.saved = false;
            this.activePage = 1;
            this.indexPage = 1;
            // this.filtertrue=false;
            if(!this.nofilterwithpagination){
              this.previousValues = {
                name: this.name,
                status: this.status,
                category_id: this.category || id  ,
                product_type: this.ProductType

              };
            }
          });
          if(this.nofilterwithpagination){
            getMethodData(`/admin/products?page=${page}&name=${  this.previousValues?.name}&category_id=${  this.previousValues.category_id }&status=${  this.previousValues?.status}&product_type=${  this.previousValues?.product_type}`,this.Token||Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadproduct = false;
                    this.errloadproduct = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadproduct = false;
                        this.errloadproduct = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.setfiltertrue(true);
                        this.products = resp?.data?.data?.data;
                        this.Count = resp.data.data.total;
                        this.perPage = resp.data.data.per_page;
                        this.saved = false;
                        this.activePage = resp?.data?.data?.current_page;
                        this.filterVisibility = true;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadproduct = false;
                  this.errloadproduct = resp?.data?.message;
                });
              })
              return;
          }
        getMethodData(`/admin/products?page=${page}&name=${  this.name}&category_id=${  this.category || id}&status=${  this.status}&product_type=${  this.ProductType}`,this.Token||Token)
        .then((resp) => {
  
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadproduct = false;
                this.errloadproduct = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loadproduct = false;
                    this.errloadproduct = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.setfiltertrue(true);
                    this.products = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.saved = false;
                    this.activePage = resp?.data?.data?.current_page;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loadproduct = false;
              this.errloadproduct = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loadproduct = false;
                if(!error.response){
                    this.errloadproduct = error?.message;
                    toast.error(error?.message);
                }
                this.errloadproduct = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };
    

       // get Category list
  getCategoryList = (id) => {
    runInAction(() => {
      this.loadCategory = true;
      this.errCategorylist = "";
    });
    getMethodData(`/public/categories?type_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCategory = false;
            this.errCategorylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCategory = false;
            this.errCategorylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.noCategorylist = true;
            } else {
              this.noCategorylist = false;
            }
            this.Categorylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCategory = false;
          this.errCategorylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCategory = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };


       // get product type list
       getProducttypeList = (Token) => {
        runInAction(() => {
          this.loadProducttype = true;
          this.errProducttypelist = "";
        });
        getMethodData(`/admin/product-types`, this.Token||Token)
          .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadProducttype = false;
                this.errProducttypelist = "";
              });
              return;
            }
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.loadProducttype = false;
                this.errProducttypelist = resp?.data?.message;
                if (resp?.data?.data?.length === 0) {
                  this.noProducttypelist = true;
                } else {
                  this.noProducttypelist = false;
                }
                this.Producttypelist = resp?.data?.data;
              });
              return;
            }
            runInAction(() => {
              this.loadProducttype = false;
              this.errProducttypelist = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
              this.loadProducttype = false;
              if (!error.response) {
                toast.error(error?.message);
              }
              toast.error(error?.response?.data?.message);
            });
            return;
          });
      };

};


export const ProductStoreContext = createContext(new ProductStore());
export const useProductStore = () => useContext(ProductStoreContext);