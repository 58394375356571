import { createContext, useContext } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import {
  getAdminChatMethodData,
  postAdminChatMethod,
  getMethodData,
  putMethod,
  patchMethodData,
  postMethodData,
  postFormData,
  getChatMethodData,
  patchStatusChangeMethod,
  deleteGroupMethod,
  deleteAdminGroupMethod,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class ChannelStore {
  groupname = "";
  groupimage = "";
  adminChatMessage = "";
  adminChatMessagesData = [];
  roomId = "";
  createdAt = "";
  chatDate = "";
  image = "";
  isHide = "";
  membersId = "";
  channelId = "";
  room = "";
  name = "";
  owner = "";
  Token = null;
  indexPage = 1;
  activePage = 1;
  Count = 1;
  perPage = "";
  filterVisibility = true;
  users = [];
  errloaduser = "";
  loaduser = false;
  nolist = false;
  singleUser = {};
  filtertrue = false;
  addloaduser = false;
  erradduser = "";
  loaduserdelete = false;
  saved = false;

  first_name = "";
  Display_name = "";
  last_name = "";
  email = "";
  gender = "";
  address = "";
  street = "";
  postal_code = "";
  country = "";
  city = "";
  state = "";
  status = "";
  phone_number = "";
  nolistdelete = false;
  userid = "";

  loadCity = false;
  errCitylist = "";
  citylist = [];
  nocitylist = false;

  loadCountry = false;
  errCountrylist = "";
  Countrylist = [];
  nocountrylist = false;

  loadState = false;
  errStatelist = "";
  Statelist = [];
  noStatelist = false;

  devicetype = "";

  nofilterwithpagination = false;
  deleteuser = "deleted";
  previousValues = {
    first_name: "",
    Display_name: "",
    last_name: "",
    email: "",
    gender: "",
    phone_number: "",
    state: "",
    city: "",
    country: "",
    status: "",
    device_type: "",
  };

  aws_accesskeyid = "";
  aws_secretaccesskey = "";

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getUsers(isloading.pagginationcount);
      this.getSetting();
      return;
    }
    if (isloading.editloading) {
      this.getuserone(isloading.id);
      return;
    }
  }
  setRoomId = (roomId) => {
    this.roomId = roomId;
  };
  setToken = (text) =>  {
    this.Token = text;
  }
  setIndexPage = (e) => {
    this.indexPage = e;
  };
  setCount = (e) => {
    this.Count = e;
  };
  setPerPage = (e) => {
    this.perPage = e;
  };
  setFilterVisibility = (e) => {
    this.filterVisibility = e;
  };
  setFirst_name = (text) => {
    this.first_name = text;
  };
  setDisplay_name = (text) => {
    this.Display_name = text;
  };
  setlast_name = (text) => {
    this.last_name = text;
  };
  setphone_number = (text) => {
    this.phone_number = (text || "").toString();
  };
  setemail = (text) => {
    this.email = text;
  };
  setgender = (text) => {
    this.gender = text;
  };
  setaddress = (text) => {
    this.address = text;
  };
  setstreet = (text) => {
    this.street = text;
  };
  setcity = (text) => {
    this.city = text;
  };
  setstate = (text) => {
    this.state = text;
  };
  setpostal_code = (text) => {
    this.postal_code = text;
  };
  setcountry = (text) => {
    this.country = text;
  };
  setstatus = (text) => {
    this.status = text;
  };
  setdevicetype = (text) => {
    this.devicetype = text;
  };
  getToken = (e) => {
    this.Token = e;
  };
  getstatusid = (text) => {
    this.userid = text;
  };
  setActivePage = (text) => {
    this.activePage = text;
  };
  blankCityState = () => {
    this.citylist = [];
    this.Statelist = [];
  };
  setfiltertrue = (e) => {
    this.filtertrue = e;
  };

  //////////  this method is not being called in parent  //////////////

  //   loadTableData = async (pageNumber) => {
  //     runInAction(() => {
  //       this.loaduser = true;
  //       this.users = [];
  //       this.saved = false;
  //       this.setIndexPage(1);
  //     });

  //     try {
  //       const response = await getChatMethodData(`/group/public/get`, this.Token);
  //       if (response?.data?.status === false) {
  //         runInAction(() => {
  //           this.loaduser = true;
  //           this.errloaduser = response?.data?.message;
  //         });
  //         return;
  //       }
  //       if (response?.data?.status === true) {
  //         runInAction(() => {
  //           this.loaduser = false;
  //           this.errloaduser = response?.data?.message;
  //           // console.log(resp?.data?.data.length);
  //           if (response?.data?.data?.data?.length === 0) {
  //             this.nolist = true;
  //           } else {
  //             this.nolist = false;
  //           }
  //           this.setCount(response.data.data.total);
  //           this.setPerPage(response.data.data.per_page);
  //           this.setActivePage(response.data.data.current_page);
  //           this.users = response?.data?.data?.data;
  //           this.filterVisibility = true;
  //         });
  //       }
  //     } catch (error) {
  //       runInAction(() => {
  //         this.loaduser = false;
  //         console.error("Error loading table data:", error);
  //       });
  //     }
  //   };

  getAdminChatMethodData = () => {
    runInAction(() => {
      this.loaduser = true;
      this.groupname = "";
      this.groupimage = "";
      this.roomId = "";
      this.indexPage = 1;
    });
    getAdminChatMethodData("/group/admin/tokee")
      .then((resp) => {
        // console.log("res", resp)
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.groupname = resp?.data?.data?.roomId?.name[0].name;
            this.groupimage = resp?.data?.data?.roomId?.image[0].image;
            this.roomId = resp?.data?.data?.roomId?._id;
            this.setRoomId(resp?.data?.data?.roomId?._id);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.message;
          toast.error(error?.response?.message);
        });
        return;
      });
  };

  // for getting admin chat messages from db

  getAdminChatMessagesData = (roomId) => {
    runInAction(() => {
      this.loaduser = true;
      this.adminChatMessagesData = [];
      this.indexPage = 1;
    });
    getAdminChatMethodData(`/group/admin/get-message/${roomId}`)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.data?.message;
            this.adminChatMessagesData = [];
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.adminChatMessagesData = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.message;
          toast.error(error?.response?.message);
        });
        return;
      });
  };

  /**
   * **To send the encrypted message from admin chat to db**
   * @param {*} data
   */
  postAdminChatMethodData = (message) => {
    runInAction(() => {
      this.loaduser = true;
      this.adminChatMessage = message;
      this.indexPage = 1;
    });
    const adminMessageBody = {
      message: message,
      message_type: "text",
      roomId: this.roomId,
    };
    postAdminChatMethod("/group/admin/send-message", adminMessageBody)
      .then((resp) => {
        // console.log("res", resp)
        if (resp?.data?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log("CHAT resp?.data?.data", resp?.data?.data);
            this.adminChatMessagesData = [
              ...this.adminChatMessagesData,
              resp?.data?.data,
            ];
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.message;
          toast.error(error?.response?.message);
        });
        return;
      });
  };

  DeletechatMessage = (message) => {
    // return
    runInAction(() => {
      this.loaduser = true;
      this.adminChatMessage = message;
      this.indexPage = 1;
    });
    const adminMessageBody = {
      messageId: message,
      roomId: this.roomId,
    };
    deleteAdminGroupMethod("/group/admin/delete-message", adminMessageBody)
      .then((resp) => {
        // console.log("res", resp)
        if (resp?.data?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log("CHAT resp?.data?.data", resp?.data?.data);
            this.adminChatMessagesData = this.adminChatMessagesData?.filter(
              (msg) => msg._id !== message
            );
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.message;
          toast.error(error?.response?.message);
        });
        return;
      });
  };
  ////////////  get group users List  ////////////
  getUsers = (pageNumber) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      this.indexPage = 1;
    });
    // getMethodData("/admin/users",this.Token)
    getAdminChatMethodData(`/group/public/get`)
      .then((resp) => {
        // console.log("resp ststus???", resp?.data?.data);
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            //console.log(resp?.data?.data.length);
            if (resp?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data)
            this.users = resp?.data?.data; //TODO:uncomment this once apis runs
            // console.log("users in store", this.users);
            this.Count = resp?.data?.data?.total;
            this.perPage = resp?.data?.data?.per_page;
            this.activePage = resp?.data?.data?.current_page;
            this.saved = false;
            this.filterVisibility = true;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            console.log("dsfsdfsdfsdf", error?.message);
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //get user edit data (show)

  getuserone = (id) => {
    getMethodData(`/admin/users/edit/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loaduser = true;
          this.errloaduser = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp.data.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.singleUser = resp?.data?.data;
            this.first_name = resp?.data?.data?.first_name;
            this.Display_name = resp?.data?.data?.display_name;
            this.last_name = resp?.data?.data?.last_name;
            this.phone_number = (resp?.data?.data?.phone_number).toString();
            this.email = resp?.data?.data?.email;
            this.gender = resp?.data?.data?.gender;
            this.address = resp?.data?.data?.address;
            this.street = resp?.data?.data?.street;
            this.city = resp?.data?.data?.city_id;
            this.state = resp?.data?.data?.state_id;
            this.postal_code = resp?.data?.data?.postal_code;
            this.country = resp?.data?.data?.country_id;
            this.status = resp?.data?.data?.status;
            this.getCountryList();
            if (resp?.data?.data?.country_id) {
              this.getStateList(resp?.data?.data?.country_id);
            }
            if (resp?.data?.data?.state_id) {
              this.getCityList(resp?.data?.data?.state_id);
            }

            //   if(resp?.data?.data?.dob){
            //     this.dob =new Date(resp?.data?.data?.dob) ;
            //   }
            //   this.usermobile = ("+1"+resp?.data?.data?.mobile).toString();
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  // user update

  edituser = (id) => {
    runInAction(() => {
      this.addloaduser = true;
      this.erradduser = "";
    });
    const formData = new FormData();
    formData.append("first_name", this.first_name);
    // formData.append("display_name", this.Display_name);
    formData.append("last_name", this.last_name);
    // formData.append("phone_number", this.phone_number.replace(/[^\d]/g, ""));
    // formData.append("email", this.email);
    formData.append("gender", this.gender);
    formData.append("address", this.address);
    formData.append("street", this.street);
    formData.append("city_id", this.city);
    formData.append("state_id", this.state);
    formData.append("postal_code", this.postal_code);
    formData.append("country_id", this.country);
    formData.append("status", this.status);
    postFormData(`/admin/users/update/${id}`, formData, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            // toast.error(resp.data.message);
            toast.error(resp.data?.data?.errors?.phone_number[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.success(resp.data.message);
            //  this.getUsers();
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };

  ////////////// Active and inactive status change here /////////////////////
  statusChange = (room, isHide) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });
    const data = {
      room: room,
      isHide: isHide,
    };
    patchStatusChangeMethod(`/group/public/hide`, data, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(1);
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  //////delete group

  deleteGroup = (roomId) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });

    const data = {
      roomId: roomId,
    };
    deleteGroupMethod(`/group/public/delete`, data, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(1);
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  NofilterwithPagination = (e) => {
    runInAction(() => {
      this.nofilterwithpagination = e;
    });
  };

  //filter user api

  getFilterUsers = (page) => {
    runInAction(() => {
      this.activePage = 1;
      this.loaduser = true;
      this.indexPage = 1;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      if (!this.nofilterwithpagination) {
        this.previousValues = {
          first_name: this.first_name,
          Display_name: this.Display_name,
          last_name: this.last_name,
          email: this.email,
          gender: this.gender,
          phone_number: this.phone_number,
          state: this.state,
          city: this.city,
          country: this.country,
          status: this.status,
          device_type: this.devicetype,
        };
      }
    });
    if (this.nofilterwithpagination) {
      getMethodData(
        `/admin/users?page=${page}&first_name=${this.previousValues.first_name}&display_name=${this.previousValues.Display_name}&last_name=${this.previousValues.last_name}&email=${this.previousValues.email}&gender=${this.previousValues.gender}&phone_number=${this.previousValues.phone_number}&state=${this.previousValues.state}&city=${this.previousValues.city}&country_id=${this.previousValues.country}&status=${this.previousValues.status}&device_type=${this.previousValues.device_type}`,
        this.Token
      ).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      });
      return;
    }
    getMethodData(
      `/admin/users?page=${page}&first_name=${this.first_name}&display_name=${this.Display_name}&last_name=${this.last_name}&email=${this.email}&gender=${this.gender}&phone_number=${this.phone_number}&state=${this.state}&city=${this.city}&country_id=${this.country}&status=${this.status}&device_type=${this.devicetype}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  getUserDeletedFilter = (page) => {
    runInAction(() => {
      this.activePage = 1;
      this.loaduser = true;
      this.indexPage = 1;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      this.loaduserdelete = true;
      this.nolistdelete = false;

      if (!this.nofilterwithpagination) {
        this.previousValues = {
          first_name: this.first_name,
          Display_name: this.Display_name,
          last_name: this.last_name,
          email: this.email,
          gender: this.gender,
          phone_number: this.phone_number,
          state: this.state,
          city: this.city,
          country: this.country,
          status: this.status,
          device_type: this.devicetype,
        };
      }
    });
    if (this.nofilterwithpagination) {
      getMethodData(
        `/admin/users?page=${page}&status=${this.deleteuser}`,
        this.Token
      ).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loaduserdelete = true;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
            } else {
              this.nolistdelete = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      });
      return;
    }
    getMethodData(
      `/admin/users?page=${page}&status=${this.deleteuser}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.loaduserdelete = true;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log(resp?.data?.data.length);

            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
            } else {
              this.nolistdelete = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;

            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          this.loaduserdelete = false;

          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // getUserDeletedFilter = (page) => {
  //   runInAction(() => {
  //     this.loaduserdelete = true;
  //     this.nolistdelete = false;
  //     this.errloaduserdelete = "";
  //     this.saved = false;
  //     this.Userdelete =[];
  //   });

  //   getMethodData(`/admin/users?page=${page}?filters[status]=${this.deleteuser}`, this.Token)
  //     .then((resp) => {
  //       if (resp?.data?.status === false) {
  //         runInAction(() => {
  //           this.loaduserdelete = false;
  //           this.errloaduserdelete = resp?.data?.message
  //         });
  //         return;
  //       }
  //       if (resp?.data?.status === true) {

  //         runInAction(() => {
  //           this.loaduserdelete = false;
  //           this.errloaduserdelete = resp?.data?.message;
  //           if (resp?.data?.data?.length == 0) {
  //             this.nolistdelete = true;
  //           } else {
  //             this.nolistdelete = false;
  //           }
  //           this.Userdelete = resp?.data?.data;
  //           this.saved = false;
  //           this.status = true;
  //         });
  //         return;
  //       }
  //       runInAction(() => {
  //         this.loaduserdelete = false;
  //         this.errloaduserdelete = resp?.data?.message;
  //       });
  //     })
  //     .catch((error) => {
  //       runInAction(() => {
  //         this.loaduserdelete = false;
  //         if (!error.response) {
  //           this.errloaduserdelete = error?.message;
  //           toast.error(error?.message);
  //         }
  //         this.errloaduserdelete = error?.response?.data?.message;
  //         toast.error(error?.response?.data?.message);
  //       })
  //       return;
  //     });
  // };

  // get country list
  getCountryList = () => {
    runInAction(() => {
      this.loadCountry = true;
      this.errCountrylist = "";
      this.Countrylist = [];
    });
    getMethodData("/public/countries", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocountrylist = true;
            } else {
              this.nocountrylist = false;
            }
            this.Countrylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCountry = false;
          this.errCountrylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCountry = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get State list
  getStateList = (id, cityyes) => {
    runInAction(() => {
      this.loadState = true;
      this.errStatelist = "";
    });
    getMethodData(`/public/states?country_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadState = false;
            this.errStatelist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadState = false;
            this.errStatelist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.noStatelist = true;
            } else {
              this.noStatelist = false;
            }
            this.Statelist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadState = false;
          this.errStatelist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadState = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get city list
  getCityList = (id) => {
    runInAction(() => {
      this.loadCity = true;
      this.errCitylist = "";
    });
    getMethodData(`/public/cities?state_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocitylist = true;
            } else {
              this.nocitylist = false;
            }
            this.citylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCity = false;
          this.errCitylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCity = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get settings List
  getSetting = () => {
    runInAction(() => {
      this.loadsetting = true;
      this.errloadsetting = "";
      this.saved = false;
    });
    getMethodData("/admin/settings", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadsetting = false;
            this.errloadsetting = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadsetting = false;
            this.errloadsetting = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.setting = resp?.data?.data;
  
            // Extract and store aws_accesskeyid and aws_secretaccesskey
            const accessKeySetting = this.setting.find(item => item.key === "AWS_ACCESS_KEY");
            const secretKeySetting = this.setting.find(item => item.key === "AWS_SECRET_ACCESS_KEY");
  
            if (accessKeySetting) {
              this.aws_accesskeyid = accessKeySetting.value;
            }
            if (secretKeySetting) {
              this.aws_secretaccesskey = secretKeySetting.value;
            }
  
            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.loadsetting = false;
          this.errloadsetting = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadsetting = false;
          if (!error.response) {
            this.errloadsetting = error?.message;
            toast.error(error?.message);
          }
          this.errloadsetting = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
}

export const ChannelStoreContext = createContext(new ChannelStore());
export const useChannelStore = () => useContext(ChannelStoreContext);
