import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class ContactStore {
    Token = null;
    BankDetails=[];
    message=[];
    Statuspopup=false;
    UserLoader=false;
    loadContact= false;
    errloadContact = "";
    saved= false;
    nolist=true;
    Contact=[];
    orderone={};
    userid=""
    transactionid="";
    orderid="";
    fromdate="";
    todate="";
    customername="";
    customeremail="";
    status="";
    customerphone="";

    ticket=""
    

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getcontactList();
            return
        }
        if(isloading.editloading){
            // this.getOrderone(isloading?.id)
        }
    }

    setstatusVoidpopup = (e) => {
        this.Statuspopup = e;
      };
      setMessage=(e)=>{
        this.message= e
      }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
      this.customeremail = text;
  }
  setcustomerphone = (text) =>  {
    this.customerphone = text;
}
    setstatus = (text) =>  {
        this.status = text;
    }
    getstatusid = (text) => {
        this.userid = text;
      }
 setBankDetails=(text)=>{
    this.BankDetails=text;
 }
 setToken = (text) =>  {
  this.Token = text;
}
 setticket = (text) =>  {
  this.ticket = text;
}
//     // get order List
    getcontactList = () => {
        runInAction(() => {
            this.loadContact= true;
            this.errloadContact = "";
            this.saved = false;
            this.Contact=[];
            this.nolist = false
            });
        getMethodData("/admin/contact-enquiries",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadContact = false;
                this.errloadContact = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadContact = false;
                this.errloadContact = resp?.data?.message;
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.Contact = resp?.data?.data?.data;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadContact = false;
                this.errloadWithdrawaL = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadContact = false;
                if(!error.response){
                    this.errloadContact = error?.message;
                    toast.error(error?.message);
                }
                this.errloadContact = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


//         //filter Order api 

        getFilterContact = (page) => {
            runInAction(() => {
                this.loadContact = true;
                this.errloadContact = "";
                this.saved = false;
              });
            getMethodData(`/admin/contact-enquiries?page=1&name=${this.customername}&email=${this.customeremail}&phone_number=${this.customerphone}&from_date=${this.fromdate}&to_date=${this.todate}&ticket_no=${this.ticket}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadContact= false;
                    this.errloadContact = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadContact = false;
                        this.errloadContact = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.Contact = resp?.data?.data?.data;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadContact = false;
                  this.errloadContact = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadContact = false;
                    if(!error.response){
                        this.errloadContact = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadContact = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
//         //Active and inactive
statusChange = () => {
    runInAction(() => {
      this.Statuspopup = true;
      this.UserLoader=true;
      this.errloaduser = "";
    });
    postMethodData(`/admin/withdrawals/mark-completed`,{ withdrawal_id : `${this.status}` },this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.UserLoader=true;
  
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.UserLoader=false;
            this.Statuspopup = false;
  
            this.errloaduser = resp?.data?.message;
            this.getwithdrawalList();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.UserLoader=true;
  
          this.errloaduser = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.UserLoader=true;
              this.Statuspopup = true;
  
              this.errloaduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.UserLoader=true;
              this.Statuspopup = true;
              this.errloaduser = error?.response?.data?.message;
        })
        return;
    })
  };
};


export const ContactStoreContext = createContext(new ContactStore());
export const useContactStoreStore = () => useContext(ContactStoreContext);