import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";
export class UserStore {
    Token = null;
    indexPage=1;
    activePage=1;
    Count=1;
    perPage="";
    filterVisibility=true;
    users = [];
    errloaduser = "";
    loaduser = false;
    nolist = false;
    singleUser = {};
    filtertrue=false;
    addloaduser = false
    erradduser=""
    deleteuser="deleted";
    saved = false
    first_name = "";
    Display_name="";
    last_name = "";
    email = "";
    gender = "";
    address = "";
    street = "";
    postal_code = "";
    country = "";
    city = "";
    state = "";
    status = "";
    usertype = "";
    phone_number = "";

    userid= "";

    loadCity= false
    errCitylist= ""
    citylist=[]
    nocitylist=false

    loadCountry= false
    errCountrylist= ""
    Countrylist=[]
    nocountrylist= false

    loadState= false
    errStatelist= ""
    Statelist=[]
    noStatelist= false

    devicetype= ""

    nofilterwithpagination= false;

    previousValues = {
      first_name: "",
      Display_name: "",
      last_name: "",
      email: "",
      gender: "",
      phone_number: "",
      state: "",
      city: "",
      country: "",
      status: "",
      usertype: "",
      device_type: ""
    };

    constructor(isloading = false,Token = null) {
        makeAutoObservable(this);
        this.Token = Token || localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getUsers(isloading.pagginationcount);
            return
        }
        if(isloading.editloading){
            this.getuserone(isloading.id);
            return
        }
    }
    setIndexPage=(e)=>{
      this.indexPage=e;
    }
    setCount=(e)=>{
      this.Count=e;
    }
    setPerPage=(e)=>{
      this.perPage=e;
    }
    getToken=(e)=>{
      this.Token=e;
      }
    setFilterVisibility=(e)=>{
      this.filterVisibility=e;
    }
    setFirst_name = (text) =>  {
        this.first_name = text;
    }
    setDisplay_name = (text) =>  {
      this.Display_name = text;
  }
    setlast_name = (text) => {
        this.last_name = text;
    }
    setphone_number = (text) => {
        this.phone_number = (text || "").toString()
    }
    setemail = (text) => {
        this.email = text;
    }
    setgender = (text) => {
        this.gender = text;
    }
    setaddress = (text) => {
        this.address = text;
    }
    setstreet = (text) => {
        this.street = text;
    }
    setcity = (text) => {
        this.city = text;
    }
    setstate = (text) => {
        this.state = text;
    }
    setpostal_code = (text) => {
        this.postal_code = text;
    }
    setcountry = (text) => {
        this.country = text;
    }
    setstatus = (text) => {
        this.status = text;
    }
    setusertype = (text) => {
        this.usertype = text;
    }
    getstatusid = (text) => {
        this.userid = text;
    }
    setActivePage = (text) =>{
      this.activePage = text;
    }
    blankCityState = () =>{
      this.citylist=[];
      this.Statelist=[];

    }
    setfiltertrue=(e)=>{
      this.filtertrue = e;
    }

    setdevicetype = (text) => {
      this.devicetype = text;
  }

  setToken = (text) =>  {
    this.Token = text;
  }

    // get user List
    getUsers = (pageNumber,Token) => {
        runInAction(() => {
            this.loaduser = true;
            this.errloaduser = "";
            this.saved = false;
            this.users=[];
            this.indexPage = pageNumber;
            });
        // getMethodData("/admin/users",this.Token)
        getMethodData(`/admin/users?page=${pageNumber}`,this.Token||Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.users = resp?.data?.data?.data;
                this.Count = resp?.data?.data?.total;
                this.perPage = resp?.data?.data?.per_page;
                this.activePage = resp?.data?.data?.current_page;
                this.saved = false;
                this.filterVisibility = true;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loaduser = false;
                if(!error.response){
                    this.errloaduser = error?.message;
                    toast.error(error?.message);
                }
                this.errloaduser = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };
    //get user edit data (show)

    getuserone =(id) => {
        getMethodData( `/admin/users/edit/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loaduser = true;
                this.errloaduser = "";
              });
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp.data.message;
              // toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
                this.loaduser = false;
                this.singleUser =  resp?.data?.data
                this.first_name = resp?.data?.data?.first_name
                this.Display_name = resp?.data?.data?.display_name
                this.last_name = resp?.data?.data?.last_name
                this.phone_number = (resp?.data?.data?.phone_number).toString()
                this.email = resp?.data?.data?.email
                this.gender = resp?.data?.data?.gender
                this.address = resp?.data?.data?.address
                this.street = resp?.data?.data?.street
                this.city = resp?.data?.data?.city_id
                this.state = resp?.data?.data?.state_id
                this.postal_code = resp?.data?.data?.postal_code
                this.country = resp?.data?.data?.country_id
                this.status = resp?.data?.data?.status
                this.getCountryList()
                console.log(resp?.data);
                if(resp?.data?.data?.country_id){
                  this.getStateList(resp?.data?.data?.country_id)
                }
                if(resp?.data?.data?.state_id){
                  this.getCityList(resp?.data?.data?.state_id)
                }
                
            //   if(resp?.data?.data?.dob){
            //     this.dob =new Date(resp?.data?.data?.dob) ;
            //   }
            //   this.usermobile = ("+1"+resp?.data?.data?.mobile).toString();
            })
            return;
          }
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp.data.message;
            toast.error(resp.data.message);
          });
        })
        .catch((error) => {
          runInAction(() => {
              if(!error.response){
                this.loaduser = false;
                this.errloaduser = error?.message;
                toast.error(error?.message);
              }
                toast.error(error?.response?.data?.message);
                this.loaduser = false;
                this.errloaduser = error?.response?.data?.message;
          })
          return;
      })
    }

     // user update

 edituser = (id) => {
    runInAction(() => {
      this.addloaduser = true;
      this.erradduser = "";
    });
    const formData = new FormData();
    formData.append("first_name", this.first_name);
    // formData.append("display_name", this.Display_name);
    formData.append("last_name", this.last_name);
    // formData.append("phone_number", this.phone_number.replace(/[^\d]/g, ""));
    // formData.append("email", this.email);
    formData.append("gender", this.gender);
    formData.append("address", this.address);
    formData.append("street", this.street);
    formData.append("city_id", this.city);
    formData.append("state_id", this.state);
    formData.append("postal_code", this.postal_code);
    formData.append("country_id", this.country);
    formData.append("status", this.status);
    postFormData(`/admin/users/update/${id}`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            // toast.error(resp.data.message);
            toast.error(resp.data?.data?.errors?.phone_number[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloaduser = false;
              this.erradduser = resp?.data?.message;
              toast.success(resp.data.message);
              //  this.getUsers();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloaduser = false;
              this.erradduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloaduser = false;
              this.erradduser = error?.response?.data?.message;
        })
        return;
    })
  };

  //Active and inactive
statusChange = (currentPage) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });
    getMethodData(`/admin/users/change-status/${this.userid}`,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          this.setIndexPage(currentPage);
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loaduser = false;
              this.errloaduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loaduser = false;
              this.errloaduser = error?.response?.data?.message;
        })
        return;
    })
  };

    NofilterwithPagination = (e) => {
      runInAction(() => {
          this.nofilterwithpagination = e
      })
    }


      //filter user api 

    getFilterUsers = (page,Token) => {
        runInAction(() => {
            this.activePage = page;
            this.loaduser = true;
            this.indexPage = page;
            this.errloaduser = "";
            this.saved = false;
            this.users=[];
            if(!this.nofilterwithpagination){
              this.previousValues = {
                first_name: this.first_name,
                Display_name: this.Display_name,
                last_name: this.last_name,
                email: this.email,
                gender: this.gender,
                phone_number: this.phone_number,
                state: this.state,
                city: this.city,
                country: this.country,
                status: this.status,
                usertype: this.usertype,
                device_type: this.devicetype
              };
            }
          });
          if(this.nofilterwithpagination){
            getMethodData(
              `/admin/users?page=${page}&first_name=${this.previousValues.first_name}&display_name=${this.previousValues.Display_name}&last_name=${this.previousValues.last_name}&email=${this.previousValues.email}&gender=${this.previousValues.gender}&phone_number=${this.previousValues.phone_number}&state=${this.previousValues.state}&city=${this.previousValues.city}&country_id=${this.previousValues.country}&status=${this.previousValues.status}&premium=${this.previousValues.usertype}&device_type=${this.previousValues.device_type}`,
              this.Token||Token
            ).then((resp) => {
              if (resp?.data?.status === false) {
                runInAction(() => {
                  this.loaduser = false;
                  this.errloaduser = resp?.data?.message
                });
                return;
              }
              if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loaduser = false;
                    this.errloaduser = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.filtertrue = true;
                    this.users = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.saved = false;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
            });
            })
            return
          }
        getMethodData(`/admin/users?page=${page}&first_name=${this.first_name}&display_name=${this.Display_name}&last_name=${this.last_name}&email=${this.email}&gender=${this.gender}&phone_number=${this.phone_number}&state=${this.state}&city=${this.city}&country_id=${this.country}&status=${this.status}&premium=${this.usertype}&device_type=${this.devicetype}`,this.Token||Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loaduser = false;
                    this.errloaduser = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.filtertrue = true;
                    this.users = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.saved = false;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loaduser = false;
                if(!error.response){
                    this.errloaduser = error?.message;
                    toast.error(error?.message);
                }
                this.errloaduser = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


          // get country list
  getCountryList = () => {
    runInAction(() => {
      this.loadCountry = true;
      this.errCountrylist = "";
      this.Countrylist = [];
    });
    getMethodData("/public/countries", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocountrylist = true;
            } else {
              this.nocountrylist = false;
            }
            this.Countrylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCountry = false;
          this.errCountrylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCountry = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };


    // get State list
    getStateList = (id,cityyes) => {
        runInAction(() => {
            this.loadState = true;
            this.errStatelist = "";
        });
        getMethodData(`/public/states?country_id=${id}`, this.Token)
            .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadState = false;
                this.errStatelist = "";
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadState = false;
                this.errStatelist = resp?.data?.message;
                if (resp?.data?.data?.length === 0) {
                    this.noStatelist = true;
                } else {
                    this.noStatelist = false;
                }
                this.Statelist = resp?.data?.data;
                });
                return;
            }
            runInAction(() => {
                this.loadState = false;
                this.errStatelist = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadState = false;
                if (!error.response) {
                toast.error(error?.message);
                }
                toast.error(error?.response?.data?.message);
            });
            return;
            });
        };


      // get city list
  getCityList = (id) => {
    runInAction(() => {
      this.loadCity = true;
      this.errCitylist = "";
    });
    getMethodData(`/public/cities?state_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocitylist = true;
            } else {
              this.nocitylist = false;
            }
            this.citylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCity = false;
          this.errCitylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCity = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
      

      //Deleted User
      getUserDeletedFilter = (page) => {
        runInAction(() => {
          this.activePage = 1;
          this.loaduser = true;
          this.indexPage = 1;
          this.errloaduser = "";
          this.saved = false;
          this.users=[];
  
        
            if(!this.nofilterwithpagination){
              this.previousValues = {
                first_name: this.first_name,
                Display_name: this.Display_name,
                last_name: this.last_name,
                email: this.email,
                gender: this.gender,
                phone_number: this.phone_number,
                state: this.state,
                city: this.city,
                country: this.country,
                status: this.status,
                device_type: this.devicetype
              };
            }
          });
          if(this.nofilterwithpagination){
            getMethodData( `/admin/users?page=${page}&status=${this.deleteuser}`, this.Token).then((resp) => {
              if (resp?.data?.status === false) {
                runInAction(() => {
                  this.loaduser = false;
                  this.loaduserdelete = true;
                  this.errloaduser = resp?.data?.message
                });
                return;
              }
              if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loaduser = false;
                    this.errloaduser = resp?.data?.message;
                    if (resp?.data?.data?.data?.length == 0) {
                      this.nolistdelete = true;
                    } else {
                      this.nolistdelete = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.filtertrue = true;
                    this.loaduserdelete = false;
                    this.users = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.saved = false;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
            });
            })
            return
          }
          getMethodData( `/admin/users?page=${page}&status=${this.deleteuser}`, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message
                this.loaduserdelete = true;
  
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loaduser = false;
                    this.errloaduser = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
  
                    if (resp?.data?.data?.data?.length == 0) {
                      this.nolistdelete = true;
                    } else {
                      this.nolistdelete = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.filtertrue = true;
                    this.loaduserdelete = false;
  
                    this.users = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.saved = false;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loaduser = false;
                this.loaduserdelete = false;
  
                if(!error.response){
                    this.errloaduser = error?.message;
                    toast.error(error?.message);
                }
                this.errloaduser = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };

};


export const UserStoreContext = createContext(new UserStore());
export const useUserStore = () => useContext(UserStoreContext);